.tabContent {
  width: 100%;
  height: 100%;
}

.restatementColumn {
  min-width: 335px;
  height: 100%;
}

.pipelineRunColumn {
  min-width: 460px;
  height: 100%;
}

.pipelineRunLogColumn {
  min-width: 400px;
  height: 100%;
}

.panel {
  background: white;
  height: calc(100% - 4px);
  border-right: 2px solid #f0f0f0;
  border-bottom: 2px solid #f0f0f0;
  margin-bottom: 4px;
}

.select {
  width: 100%;
}

.item {
  margin-right: 8px;
}
