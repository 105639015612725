.app {
  width: 100%;
  height: 100%;
}

.app-logo {
  color: white;
  font-size: x-large;
  text-align: center;
  margin-top: 12px;
}
