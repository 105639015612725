@import '~antd/dist/antd.css';

#root {
  height: 100%;
}

.pls-tabs {
  height: 100%;
  padding: 8px 8px 2px;
}

.pls-tabs .ant-tabs-content {
  height: 100% !important;
  background: white;
  overflow: auto;
}

.pls-tabs .ant-tabs-nav {
  margin-bottom: 0 !important;
}

.pls-tabs .ant-input-number-handler-wrap {
  display: none;
}

.pls-tabs .ant-tabs-nav-add {
  margin: 4px 4px 4px 1px;
  border-radius: 4px;
}

.pls-tabs .ant-spin {
  width: 100%;
  position: absolute;
  color: rgb(92, 107, 119);
  top: 50px;
  bottom: 0;
  left: 0;
  right: 0;
  height: 20%;
  min-height: 70px;
  width: 50%;
  min-width: 65px;
  margin: auto;
}

.pls-tabs .ant-spin-nested-loading {
  height: 100%;
  overflow: auto;
}

.pls-tabs .ant-spin-nested-loading > div {
  position: absolute;
  height: 100%;
  width: 100%;
}

.pls-tabs .ant-empty {
  position: absolute;
  color: rgb(92, 107, 119);
  top: 50px;
  bottom: 0;
  left: 0;
  right: 0;
  height: 20%;
  min-height: 70px;
  width: 50%;
  min-width: 65px;
  margin: auto;
}
